const urls = require('./urls');

module.exports = {
  /* This file contains definitions to build breadcrumbs. This contains definitions managed by NeoAesir component.

  You can understand this file as a definition of nodes of a tree.

  There is *NOT* a direct correspondence between nodes of this tree and pages. For instance:

  ficha_hotel_colorado_creek  is a node of this tree and it is painted with the template ficha_hotel.

  The properties of every node are:

  parent    -> which is the preceding element of the breadcrumb
  getParent -> equivalent to parent, when the parent has to be determined following some logic and has to be
               evaluated by a function. (This has precence over parent)
  page      -> which is the page that paints this element.
  options   -> if the page is a template, options to correctly paint the page.
  name      -> name to write in the breadcumb. It only works for elements in the middle of the breadcumb path
              if you need to customise the name of the last element, you should pass it directly to NeoAesir
  */

  index: {
    parent: null,
    page: 'index',
    name: [
      { es: 'Port Aventura World' },
      { ca: 'Port Aventura World' },
      { fr: 'Port Aventura World' },
      { en: 'Port Aventura World' },
    ],
  },
  distributiva_parques: {
    parent: 'index',
    page: 'distributiva_parques',
    name: [{ es: 'Parques' }, { ca: 'Parcs' }, { fr: 'Parcs' }, { en: 'Parks' }],
  },
  pys_trucos: {
    parent: 'plan_your_stay',
    page: 'pys_trucos',
  },
  pys_recomendaciones: {
    parent: 'plan_your_stay',
    page: 'pys_recomendaciones',
  },
  distributiva_entorno: {
    parent: 'index',
    page: 'distributiva_entorno',
    name: [
      { es: 'Destino' },
      { ca: 'Destinacio' },
      { fr: 'Destination' },
      { en: 'Destination' },
    ],
  },
  distributiva_promociones: {
    parent: 'index',
    page: 'distributiva_promociones',
    name: [
      { es: 'Promociones' },
      { ca: 'Promocions' },
      { fr: 'Promotions' },
      { en: 'Promotions' },
    ],
  },
  distributiva_mundos: {
    parent: 'index',
    page: 'distributiva_mundos',
    name: [{ es: 'Zonas' }, { ca: 'Zones' }, { fr: 'Aires' }, { en: 'Areas' }],
  },
  distributiva_hoteles: {
    parent: 'index',
    page: 'distributiva_hoteles',
    name: [
      { es: 'Hoteles' },
      { ca: 'Hotels' },
      { fr: 'Hôtels' },
      { en: 'Hotels' },
    ],
  },
  distributiva_tiendas_pa: {
    parent: 'ficha_parques',
    page: 'distributiva_tiendas_pa',
    name: [
      { es: 'Tiendas' },
      { ca: 'Botigues' },
      { fr: 'Boutiques' },
      { en: 'Shops' },
    ],
  },
  hotel_roulette: {
    parent: 'distributiva_hoteles',
    page: 'hotel_roulette',
  },
  distributiva_tiendas_fl: {
    parent: 'ficha_parques_fl',
    page: 'distributiva_tiendas_fl',
    name: [
      { es: 'Tiendas' },
      { ca: 'Botigues' },
      { fr: 'Boutiques' },
      { en: 'Shops' },
    ],
  },
  distributiva_tiendas_ap: {
    parent: 'ficha_parques_ap',
    page: 'distributiva_tiendas_ap',
    name: [
      { es: 'Tiendas' },
      { ca: 'Botigues' },
      { fr: 'Boutiques' },
      { en: 'Shops' },
    ],
    options: ['aquatic-park'],
  },
  distributiva_atracciones_pa: {
    parent: 'index',
    page: 'distributiva_atracciones_pa',
    name: [
      { es: 'Atracciones' },
      { ca: 'Atraccions' },
      { fr: 'Attractions' },
      { en: 'Rides' },
    ],
  },
  distributiva_atracciones_fl: {
    parent: 'ficha_parques_fl',
    page: 'distributiva_atracciones_fl',
    name: [
      { es: 'Atracciones' },
      { ca: 'Atraccions' },
      { fr: 'Attractions' },
      { en: 'Rides' },
    ],
    options: ['ferrari-land'],
  },
  distributiva_atracciones_ap: {
    parent: 'ficha_parques',
    page: 'distributiva_atracciones_ap',
    name: [
      { es: 'Atracciones' },
      { ca: 'Atraccions' },
      { fr: 'Attractions' },
      { en: 'Rides' },
    ],
    options: ['ferrari-land'],
  },
  experience_fl: {
    parent: 'ficha_parques_fl',
    page: 'experience_fl',
    name: [
      { es: 'Experience' },
      { ca: 'Experience' },
      { fr: 'Experience' },
      { en: 'Experience' },
    ],
  },
  kids_area: {
    parent: 'distributiva_atracciones_fl',
    page: 'kids_area',
    name: [
      { es: 'Kids Area' },
      { ca: 'Kids Area' },
      { fr: 'Kids Area' },
      { en: 'Kids Area' },
    ],
  },
  distributiva_restaurantes_pa: {
    parent: 'ficha_parques',
    page: 'distributiva_restaurantes_pa',
    name: [
      { es: 'Restaurantes' },
      { ca: 'Restaurants' },
      { fr: 'Restaurants' },
      { en: 'Restaurants' },
    ],
  },
  material_grafico_halloween: {
    parent: 'dist_halloween',
    page: 'material_grafico_halloween',
  },
  material_grafico_navidad: {
    parent: 'navidad',
    page: 'material_grafico_navidad',
  },
  distributiva_restaurantes_ap: {
    parent: 'ficha_parques_ap',
    page: 'distributiva_restaurantes_ap',
    name: [
      { es: 'Restaurantes' },
      { ca: 'Restaurants' },
      { fr: 'Restaurants' },
      { en: 'Restaurants' },
    ],
    options: ['aquatic-park'],
  },
  distributiva_restaurantes_fl: {
    parent: 'ficha_parques_fl',
    page: 'distributiva_restaurantes_fl',
    name: [
      { es: 'Restaurantes' },
      { ca: 'Restaurants' },
      { fr: 'Restaurants' },
      { en: 'Restaurants' },
    ],
    options: ['ferrari-land'],
  },
  distributiva_espectaculos_pa: {
    parent: 'ficha_parques',
    page: 'distributiva_espectaculos_pa',
    name: [
      { es: 'Espectáculos' },
      { ca: 'Espectacles' },
      { fr: 'Spectacles' },
      { en: 'Shows' },
    ],
  },
  shows_navidad: {
    parent: 'navidad',
    page: 'shows_navidad',
    name: [
      { es: 'Espectáculos' },
      { ca: 'Espectacles' },
      { fr: 'Spectacles' },
      { en: 'Shows' },
    ],
  },
  distributiva_espectaculos_fl: {
    parent: 'ficha_parques_fl',
    page: 'distributiva_espectaculos_fl',
    name: [
      { es: 'Espectáculos' },
      { ca: 'Espectacles' },
      { fr: 'Spectacles' },
      { en: 'Shows' },
    ],
  },
  sport_wellness: {
    parent: 'distributiva_hoteles',
    page: 'sport_wellness',
    name: [
      { es: 'Sport & Wellness' },
      { ca: 'Sport & Wellness' },
      { fr: 'Sport & Wellness' },
      { en: 'Sport & Wellness' },
    ],
  },
  novedades: {
    parent: 'index',
    page: 'novedades',
    name: [
      { es: 'Novedades 2020' },
      { ca: 'Novetats 2020' },
      { fr: 'Nouveautes 2020' },
      { en: 'News 2020' },
    ],
  },
  easteregg: {
    parent: 'novedades',
    page: 'easteregg',
  },
  laliga: {
    parent: 'index',
    page: 'laliga',
  },
  laliga_thebeatchallenge: {
    parent: 'index',
    page: 'laliga',
  },
  laliga_twenty_nines: {
    parent: 'index',
    page: 'laliga',
  },
  hotel_pase_express: {
    parent: 'distributiva_hoteles',
    page: 'hotel_pase_express',
    name: [{ es: 'Pase express' }],
  },
  pase_express: {
    parent: 'entradas',
    page: 'pase_express',
    name: [
      { es: 'Pase express' },
      { ca: 'Passi Express' },
      { fr: 'Pass Express' },
      { en: 'Express Pass' },
    ],
    options: [''],
  },
  distributiva_excursiones_julia: {
    parent: 'distr_promociones',
    page: 'distributiva_excursiones_julia',
    name: [
      { es: 'Excursion Julia' },
      { ca: 'Excursion Julia' },
      { fr: 'Excursion Julia' },
      { en: 'Excursion Julia' },
    ],
  },
  servicios_parques: {
    parent: 'index',
    page: 'servicios_parques',
    name: [
      { es: 'Servicios' },
      { ca: 'Serveis' },
      { fr: 'Les services' },
      { en: 'Services' },
    ],
  },
  como_llegar: {
    parent: 'index',
    page: 'como_llegar',
    name: [
      { es: 'Cómo llegar' },
      { ca: 'Com arribar-hi?' },
      { fr: 'Les services' },
      { en: 'Getting here' },
    ],
  },
  campings_asociados: {
    // TO DO
    parent: 'nosotros',
    page: 'campings_asociados',
  },
  nosotros: {
    parent: 'index',
    page: 'nosotros',
    name: [{ es: 'Nosotros' }, { ca: 'Nosaltres' }, { fr: 'Nous' }, { en: 'Us' }],
  },
  intolerancias_alergias: {
    parent: 'index',
    page: 'intolerancias_alergias',
  },
  nuestro_compromiso: {
    parent: 'index',
    page: 'nuestro_compromiso',
    name: [
      { es: 'Nuestro compromiso' },
      { ca: 'El nostre compromís' },
      { fr: 'Notre engagement' },
      { en: 'Our commitment' },
    ],
  },
  dossier_prensa: {
    parent: 'index',
    page: 'dossier_prensa',
    name: [
      { es: 'Dossier de Prensa' },
      { ca: 'Dossier de Premsa' },
      { fr: 'Dossier de Presse' },
      { en: 'Press Kit' },
    ],
  },
  gabinete_prensa: {
    parent: 'index',
    page: 'gabinete_prensa',
    name: [
      { es: 'Dossier de Prensa' },
      { ca: 'Dossier de Premsa' },
      { fr: 'Dossier de Presse' },
      { en: 'Press Kit' },
    ],
  },
  material_grafico: {
    parent: 'index',
    page: 'material_grafico',
    name: [
      { es: 'Material Gráfico' },
      { ca: 'Material gràfic' },
      { fr: 'Matériel graphique' },
      { en: 'Graphic Material' },
    ],
  },
  ficha_material_grafico: {
    parent: 'material_grafico',
    page: 'ficha_material_grafico',
  },
  indoor: {
    parent: 'index',
    page: 'indoor',
  },
  distributiva_actividades: {
    parent: 'index',
    page: 'distributiva_actividades',
    name: [
      { es: 'Indoor Pass Experience' },
      { ca: 'Indoor Pass Experience' },
      { fr: 'Indoor Pass Experience' },
      { en: 'Indoor Pass Experience' },
    ],
  },
  ficha_actividades: {
    parent: 'distributiva_actividades',
    page: 'ficha_actividades',
  },
  business: {
    parent: 'index',
    page: 'business',
  },
  fundacion: {
    parent: 'index',
    page: 'fundacion',
  },
  agencia_viajes: {
    parent: 'index',
    page: 'agencia_viajes',
  },
  plan_your_stay: {
    parent: 'index',
    page: 'plan_your_stay',
    name: [
      { es: 'Planifica tu viaje' },
      { fr: 'Planifiez votre séjour' },
      { ca: 'Planifica el teu viatge' },
      { en: 'Plan your trip' },
    ],
  },
  plan_your_stay_types: {
    parent: 'plan_your_stay',
    page: 'plan_your_stay_types',
  },
  shows_halloween: {
    parent: 'dist_halloween',
    page: 'shows_halloween',
    name: [
      { es: 'Espectáculos' },
      { fr: 'Spectacles' },
      { ca: 'Espectacles' },
      { en: 'Shows' },
    ],
  },
  ficha_parques: {
    parent: 'index',
    page: 'ficha_parques',
    options: ['portaventura'],
    name: [
      { es: 'PortAventura Park' },
      { ca: 'PortAventura Park' },
      { fr: 'PortAventura Park' },
      { en: 'PortAventura Park' },
    ],
  },
  ficha_parques_fl: {
    parent: 'index',
    page: 'ficha_parques',
    options: ['ferrari-land'],
    name: [
      { es: 'Ferrari Land' },
      { ca: 'Ferrari Land' },
      { fr: 'Ferrari Land' },
      { en: 'Ferrari Land' },
    ],
  },

  ficha_parques_ap: {
    parent: 'index',
    page: 'ficha_parques',
    options: ['aquatic-park'],
    name: [
      { es: 'Caribe Aquatic Park' },
      { ca: 'Caribe Aquatic Park' },
      { fr: 'Caribe Aquatic Park' },
      { en: 'Caribe Aquatic Park' },
    ],
  },
  dist_halloween: {
    parent: 'index',
    page: 'ficha_parques',
    options: ['halloween'],
    name: [
      { es: 'Halloween' },
      { ca: 'Halloween' },
      { fr: 'Halloween' },
      { en: 'Halloween' },
    ],
  },
  navidad: {
    parent: 'index',
    page: 'navidad',
    options: ['christmas'],
    name: [
      { es: 'Navidad' },
      { ca: 'Nadal' },
      { fr: 'Noel' },
      { en: 'Christmas' },
    ],
  },
  ficha_mundos: {
    parent: 'index',
    page: 'ficha_mundos',
    options: ['mediterrania'],
    name: [
      { es: 'Mediterrania' },
      { ca: 'Mediterrania' },
      { fr: 'Mediterrania' },
      { en: 'Mediterrania' },
    ],
  },
  medio_ambiente: {
    parent: 'index',
    page: 'medio_ambiente',
    name: [
      { es: 'Medio ambiente' },
      { ca: 'Medi Ambient' },
      { fr: 'Environnement' },
      { en: 'Environment' },
    ],
  },
  partners: {
    parent: 'nosotros',
    page: 'partners',
  },
  ficha_atracciones: {
    page: 'ficha_atracciones',
    getParent(options) {
      if (options[0] === 'ferrari-land') return 'distributiva_atracciones_fl';
      if (options[0] === 'aquatic-park') return 'distributiva_atracciones_ap';
      return 'distributiva_atracciones_pa';
    },
  },

  ficha_tiendas: {
    parent: 'ficha_parques',
    page: 'ficha_tiendas',
    getParent(options) {
      if (options[0] === 'ferrari-land') return 'distributiva_tiendas_fl';
      if (options[0] === 'aquatic-park') return 'distributiva_tiendas_ap';
      return 'distributiva_tiendas_pa';
    },
  },
  ficha_hoteles: {
    page: 'ficha_hoteles',
    getParent(options) {
      if (options[0] === 'gold-river') return 'ficha_hotel_gold_river';
      if (options[0] === 'port-aventura') return 'ficha_hotel_port_aventura';
      if (options[0] === 'mansion-de-lucy') return 'ficha_hotel_mansion_de_lucy';
      if (options[0] === 'caribe') return 'ficha_hotel_caribe';
      if (options[0] === 'el-paso') return 'ficha_hotel_el_paso';
      if (options[0] === 'colorado-creek') return 'ficha_hotel_colorado_creek';
      return 'distributiva_hoteles';
    },
  },

  ficha_hotel_gold_river: {
    parent: 'distributiva_hoteles',
    page: 'ficha_hoteles',
    options: ['gold-river', ''],
    name: [
      { es: 'Hotel Gold River' },
      { ca: 'Hotel Gold River' },
      { fr: 'Hotel Gold River' },
      { en: 'Hotel Gold River' },
    ],
  },
  ficha_hotel_port_aventura: {
    parent: 'distributiva_hoteles',
    page: 'ficha_hoteles',
    options: ['port-aventura', ''],
    name: [
      { es: 'Hotel PortAventura' },
      { ca: 'Hotel PortAventura' },
      { fr: 'Hotel PortAventura' },
      { en: 'Hotel PortAventura' },
    ],
  },
  ficha_hotel_mansion_de_lucy: {
    parent: 'distributiva_hoteles',
    page: 'ficha_hoteles',
    options: ['mansion-de-lucy', ''],
    name: [
      { es: 'Hotel Mansión de Lucy' },
      { ca: 'Hotel Mansión de Lucy' },
      { fr: 'Hotel Mansión de Lucy' },
      { en: 'Hotel Mansión de Lucy' },
    ],
  },
  ficha_hotel_caribe: {
    parent: 'distributiva_hoteles',
    page: 'ficha_hoteles',
    options: ['caribe', ''],
    name: [
      { es: 'Hotel Caribe' },
      { ca: 'Hotel Caribe' },
      { fr: 'Hotel Caribe' },
      { en: 'Hotel Caribe' },
    ],
  },
  ficha_hotel_el_paso: {
    parent: 'distributiva_hoteles',
    page: 'ficha_hoteles',
    options: ['el-paso', ''],
    name: [
      { es: 'Hotel El Paso' },
      { ca: 'Hotel El Paso' },
      { fr: 'Hotel El Paso' },
      { en: 'Hotel El Paso' },
    ],
  },
  ficha_hotel_colorado_creek: {
    parent: 'distributiva_hoteles',
    page: 'ficha_hoteles',
    options: ['colorado-creek', ''],
    name: [
      { es: 'Hotel Colorado Creek' },
      { ca: 'Hotel Colorado Creek' },
      { fr: 'Hotel Colorado Creek' },
      { en: 'Hotel Colorado Creek' },
    ],
  },
  condiciones_generales: {
    parent: 'index',
    page: 'condiciones_generales',
    name: [
      { es: 'Condiciones Generales' },
      { ca: 'Condicions generals' },
      { fr: 'Condiciones générales' },
      { en: 'General Conditions' },
    ],
  },
  healthy: {
    parent: 'index',
    page: 'healthy',
  },
  politica_privacidad: {
    parent: 'index',
    page: 'politica_privacidad',
  },
  pase_anual: { parent: 'index' },
  ficha_grupos: {
    page: 'ficha_grupos',
    parent: 'entradas',
    options: [
      'grupos-vacacionales',
      'grupos-escolares',
      'personas-discapacidad-pmr',
      'familia-numerosa',
    ],
    name: [
      { es: 'Grupos' },
      { ca: 'grups' },
      { fr: 'groupes' },
      { en: 'groups' },
    ],
  },
  ficha_prensa: {
    page: 'ficha_prensa',
    parent: 'index',
    options: [
      'notas-de-prensa-2010',
      'notas-de-prensa-2011',
      'notas-de-prensa-2012',
      'notas-de-prensa-2013',
      'notas-de-prensa-2014',
      'notas-de-prensa-2015',
      'notas-de-prensa-2016',
      'notas-de-prensa-2017',
      'notas-de-prensa-2018',
      'notas-de-prensa-2019',
      'notas-de-prensa-2020',
    ],
  },
  reconocimientos: {
    page: 'reconocimientos',
    parent: 'nosotros',
  },
  aviso_legal: {
    page: 'aviso_legal',
    parent: 'index',
  },
  condiciones_compra: {
    page: 'condiciones_compra',
    parent: 'index',
  },
  traslados: {
    page: 'traslados',
    parent: 'index',
  },
  ficha_wellness: {
    page: 'distributiva_hoteles',
    parent: 'sport_wellness',
    name: [
      { es: 'Sport & Wellness' },
      { ca: 'Sport & Wellness' },
      { fr: 'Sport & Wellness' },
      { en: 'Sport & Wellness' },
    ],
  },
  ficha_promociones: {
    parent: 'distributiva_promociones',
  },
  promotion_boxing_day: {
    parent: 'distributiva_promociones',
  },
  checkin: {
    parent: 'distributiva_hoteles',
  },
  app: {
    parent: 'index',
  },
  ficha_habitacion: {
    page: 'ficha_habitacion',
    getParent(options) {
      if (options[0] === 'gold-river') return 'ficha_hotel_gold_river';
      if (options[0] === 'port-aventura') return 'ficha_hotel_port_aventura';
      if (options[0] === 'mansion-de-lucy') return 'ficha_hotel_mansion_de_lucy';
      if (options[0] === 'caribe') return 'ficha_hotel_caribe';
      if (options[0] === 'el-paso') return 'ficha_hotel_el_paso';
      if (options[0] === 'colorado-creek') return 'ficha_hotel_colorado_creek';
      return 'distributiva_hoteles';
    },
  },
  trabajar_nosotros: {
    page: 'trabajar_nosotros',
    parent: 'nosotros',
  },
  promotion_halloween: {
    page: 'promotion_halloween',
    parent: 'dist_halloween',
  },
  promotion_navidad: {
    page: 'promotion_navidad',
    parent: 'navidad',
  },
  ficha_espectaculos_navidad: {
    page: 'ficha_espectaculos_navidad',
    parent: 'shows_navidad',
  },
  ficha_espectaculos: {
    page: 'ficha_espectaculos',
    getParent(options) {
      if (options[0] === '') return 'distributiva_espectaculos_pa';
      if (options[0] === 'aquatic_park') return 'ficha_parques_pa';
      if (options[0] === 'ferrari-land') return 'distributiva_espectaculos_fl';
      if (options[0] === 'halloween') return 'shows_halloween';
      if (options[0] === 'navidad') return 'shows_navidad';
      return 'ficha_parques';
    },
  },
  ficha_espectaculos_as_tiendas: {
    page: 'ficha_espectaculos',
    parent: 'distributiva_tiendas_pa',
  },
  ficha_hotel_restaurante: {
    page: 'ficha_hotel_restaurante',
    getParent(options) {
      if (options[0] === 'gold-river') return 'ficha_hotel_gold_river';
      if (options[0] === 'port-aventura') return 'ficha_hotel_port_aventura';
      if (options[0] === 'mansion-de-lucy') return 'ficha_hotel_mansion_de_lucy';
      if (options[0] === 'caribe') return 'ficha_hotel_caribe';
      if (options[0] === 'el-paso') return 'ficha_hotel_el_paso';
      if (options[0] === 'colorado-creek') return 'ficha_hotel_colorado_creek';
      return 'distributiva_hoteles';
    },
  },
  ficha_restaurantes: {
    page: 'ficha_restaurantes',
    getParent(options) {
      if (options[1] === 'reggae-cafe') return 'distributiva_restaurantes_ap';
      if (options[1] === 'focacceria') return 'distributiva_restaurantes_ap';
      if (options[1] === 'the-surfer') return 'distributiva_restaurantes_ap';
      if (options[1] === 'la-cabana') return 'distributiva_restaurantes_ap';
      if (options[1] === 'puntos-de-alimentacion-aquatic-park')
        return 'distributiva_restaurantes_ap';

      if (options[0] === 'aquatic-park') return 'distributiva_restaurantes_ap';
      if (options[0] === 'ferrari-land') return 'distributiva_restaurantes_fl';
      return 'distributiva_restaurantes_pa';
    },
  },
  ficha_calendar: {
    page: 'ficha_calendar',
    getParent(options, locale) {
      if (options[0] === '') return 'index';
      if (options[0] === 'aquatic-park') return 'ficha_parques_ap';
      if (options[0] === 'ferrari-land') return 'ficha_parques_fl';
      if (options[0] === urls.templates_multilang.ficha_calendar.options.item[locale][3])
        return 'ficha_hotel_gold_river';
      if (options[0] === urls.templates_multilang.ficha_calendar.options.item[locale][4])
        return 'ficha_hotel_port_aventura';
      if (options[0] === urls.templates_multilang.ficha_calendar.options.item[locale][5])
        return 'ficha_hotel_mansion_de_lucy';
      if (options[0] === urls.templates_multilang.ficha_calendar.options.item[locale][6])
        return 'ficha_hotel_caribe';
      if (options[0] === urls.templates_multilang.ficha_calendar.options.item[locale][7])
        return 'ficha_hotel_el_paso';
      if (options[0] === urls.templates_multilang.ficha_calendar.options.item[locale][8])
        return 'ficha_hotel_colorado_creek';
      return 'distributiva_hoteles';
    },
  },
  preguntas_frecuentes_main: {
    page: 'preguntas_frecuentes',
    parent: 'index',
    options: [''],
    name: [
      { es: 'Preguntas Frecuentes' },
      { fr: 'Questions Frequentes' },
      { ca: 'Preguntes Frequents' },
      { en: 'FAQs' },
    ],
  },
  preguntas_frecuentes: {
    page: 'preguntas_frecuentes',
    getParent(options) {
      if (options[0] === '') return 'index';
      return 'preguntas_frecuentes_main';
    },
  },
  ficha_habitacion_hotel_mansion_de_lucy: {
    parent: 'ficha_hotel_mansion_de_lucy',
    page: 'ficha_hoteles',
    options: ['mansion-de-lucy', 'habitaciones'],
    name: [
      { es: 'Habitaciones' },
      { ca: 'Habitacions' },
      { fr: 'Chambres' },
      { en: 'Bedrooms' },
    ],
  },
  ficha_habitacion_hotel_port_aventura: {
    parent: 'ficha_hotel_port_aventura',
    page: 'ficha_hoteles',
    options: ['port-aventura', 'habitaciones'],
    name: [
      { es: 'Habitaciones' },
      { ca: 'Habitacions' },
      { fr: 'Chambres' },
      { en: 'Bedrooms' },
    ],
  },
  ficha_habitacion_hotel_gold_river: {
    parent: 'ficha_hotel_gold_river',
    page: 'ficha_hoteles',
    options: ['gold-river', 'habitaciones'],
    name: [
      { es: 'Habitaciones' },
      { ca: 'Habitacions' },
      { fr: 'Chambres' },
      { en: 'Bedrooms' },
    ],
  },
  ficha_habitacion_hotel_caribe: {
    parent: 'ficha_hotel_caribe',
    page: 'ficha_hoteles',
    options: ['caribe', 'habitaciones'],
    name: [
      { es: 'Habitaciones' },
      { ca: 'Habitacions' },
      { fr: 'Chambres' },
      { en: 'Bedrooms' },
    ],
  },
  ficha_habitacion_hotel_el_paso: {
    parent: 'ficha_hotel_el_paso',
    page: 'ficha_hoteles',
    options: ['el-paso', 'habitaciones'],
    name: [
      { es: 'Habitaciones' },
      { ca: 'Habitacions' },
      { fr: 'Chambres' },
      { en: 'Bedrooms' },
    ],
  },
  ficha_habitacion_hotel_colorado_creek: {
    parent: 'ficha_hotel_colorado_creek',
    page: 'ficha_hoteles',
    options: ['colorado-creek', 'habitaciones'],
    name: [
      { es: 'Habitaciones' },
      { ca: 'Habitacions' },
      { fr: 'Chambres' },
      { en: 'Bedrooms' },
    ],
  },
  ficha_detalle_habitacion: {
    getParent(options) {
      if (options[0] === 'gold-river') return 'ficha_habitacion_hotel_gold_river';
      if (options[0] === 'port-aventura') return 'ficha_habitacion_hotel_port_aventura';
      if (options[0] === 'mansion-de-lucy') return 'ficha_habitacion_hotel_mansion_de_lucy';
      if (options[0] === 'caribe') return 'ficha_habitacion_hotel_caribe';
      if (options[0] === 'el-paso') return 'ficha_habitacion_hotel_el_paso';
      if (options[0] === 'colorado-creek') return 'ficha_habitacion_hotel_colorado_creek';
      return 'distributiva_hoteles';
    },
    page: 'ficha_habitacion',
  },
  ficha_restaurante_hotel_mansion_de_lucy: {
    parent: 'ficha_hotel_mansion_de_lucy',
    page: 'ficha_hoteles',
    options: ['mansion-de-lucy', 'restaurantes'],
    name: [
      { es: 'Restaurantes' },
      { ca: 'Restaurants' },
      { fr: 'Restaurants' },
      { en: 'Restaurants' },
    ],
  },
  ficha_restaurante_hotel_gold_river: {
    parent: 'ficha_hotel_gold_river',
    page: 'ficha_hoteles',
    options: ['gold-river', 'restaurantes'],
    name: [
      { es: 'Restaurantes' },
      { ca: 'Restaurants' },
      { fr: 'Restaurants' },
      { en: 'Restaurants' },
    ],
  },
  ficha_restaurante_hotel_port_aventura: {
    parent: 'ficha_hotel_port_aventura',
    page: 'ficha_hoteles',
    options: ['port-aventura', 'restaurantes'],
    name: [
      { es: 'Restaurantes' },
      { ca: 'Restaurants' },
      { fr: 'Restaurants' },
      { en: 'Restaurants' },
    ],
  },
  ficha_restaurante_hotel_caribe: {
    parent: 'ficha_hotel_caribe',
    page: 'ficha_hoteles',
    options: ['caribe', 'restaurantes'],
    name: [
      { es: 'Restaurantes' },
      { ca: 'Restaurants' },
      { fr: 'Restaurants' },
      { en: 'Restaurants' },
    ],
  },
  ficha_restaurante_hotel_el_paso: {
    parent: 'ficha_hotel_el_paso',
    page: 'ficha_hoteles',
    options: ['el-paso', 'restaurantes'],
    name: [
      { es: 'Restaurantes' },
      { ca: 'Restaurants' },
      { fr: 'Restaurants' },
      { en: 'Restaurants' },
    ],
  },
  ficha_restaurante_hotel_colorado_creek: {
    parent: 'ficha_hotel_colorado_creek',
    page: 'ficha_hoteles',
    options: ['colorado-creek', 'restaurantes'],
    name: [
      { es: 'Restaurantes' },
      { ca: 'Restaurants' },
      { fr: 'Restaurants' },
      { en: 'Restaurants' },
    ],
  },
  ficha_detalle_restaurante: {
    getParent(options) {
      if (options[0] === 'gold-river') return 'ficha_restaurante_hotel_gold_river';
      if (options[0] === 'port-aventura') return 'ficha_restaurante_hotel_port_aventura';
      if (options[0] === 'mansion-de-lucy') return 'ficha_restaurante_hotel_mansion_de_lucy';
      if (options[0] === 'caribe') return 'ficha_restaurante_hotel_caribe';
      if (options[0] === 'el-paso') return 'ficha_restaurante_hotel_el_paso';
      if (options[0] === 'colorado-creek') return 'ficha_restaurante_hotel_colorado_creek';
      return 'distributiva_hoteles';
    },
    page: 'ficha_hotel_restaurante',
  },
  ficha_destiny: {
    parent: 'distributiva_entorno',
    page: 'ficha_destiny',
  },
  ficha_entorno: {
    getParent(options) {
      if (options.park === 'ferrari-land') return 'distributiva_atracciones_fl';
      if (options.park === 'ferrari-land') return 'distributiva_atracciones_fl';
      return 'distributiva_atracciones_pa';
    },
  },
  entradas: {
    parent: 'index',
    page: 'entradas',
    name: [
      { es: 'Entradas' },
      { ca: 'Entrades' },
      { fr: 'Entrees' },
      { en: 'Tickets' },
    ],
  },
  entradas_fl: {
    parent: 'entradas',
    page: 'entradas_fl',
    name: [
      { es: 'Entradas' },
      { ca: 'Entrades' },
      { fr: 'Entrees' },
      { en: 'Tickets' },
    ],
  },
  entradas_cap: {
    parent: 'entradas',
    page: 'entradas_cap',
  },
  entradas_bs: {
    parent: 'entradas',
    page: 'entradas_bs',
  },
  distr_promociones: {
    parent: 'index',
    page: 'distr_promociones',
    name: [
      { es: 'Promociones' },
      { ca: 'Promocions' },
      { fr: 'Promotions' },
      { en: 'Promotions' },
    ],
  },
  ficha_promo_hotel_entrada_halloween: {
    parent: 'distr_promociones',
    page: 'ficha_promo_hotel_entrada_halloween',
    name: [
      { es: 'hotel-entrada-halloween' },
      { ca: 'hotel-entrada-halloween' },
      { fr: 'hotel-entrada-halloween' },
      { en: 'hotel-entrada-halloween' },
    ],
  },
  tarjeta_regalo: {
    parent: 'distributiva_actividades',
    page: 'tarjeta_regalo',
  },
  ficha_promo_vacaciones: {
    parent: 'distr_promociones',
    page: 'ficha_promo_vacaciones',
  },
  mejores_atracciones: {
    parent: 'distributiva_atracciones_pa',
    page: 'mejores_atracciones',
  },
  ficha_promo_arbol: {
    parent: 'distributiva_actividades',
    page: 'ficha_promo_arbol',
  },
  mesa_navidad: {
    parent: 'distributiva_actividades',
    page: 'mesa_navidad',
  },
  papel_de_regalo: {
    parent: 'distributiva_actividades',
    page: 'papel_de_regalo',
  },
  mejores_sitios_para_comer: {
    parent: 'distributiva_restaurantes_pa',
    page: 'mejores_sitios_para_comer',
  },
  requisitos_altura: {
    parent: 'distributiva_atracciones_pa',
    page: 'requisitos_altura',
  },
  requisitos_altura_fl: {
    parent: 'distributiva_atracciones_fl',
    page: 'requisitos_altura_fl',
  },
  requisitos_altura_cap: {
    parent: 'distributiva_atracciones_ap',
    page: 'requisitos_altura_cap',
  },
  semana_santa: {
    parent: 'index',
    page: 'semana_santa',
  },
  condiciones_hoteles: {
    parent: 'index',
    page: 'condiciones_hoteles',
  },
  delion_experience: {
    parent: 'index',
    page: 'delion_experience',
  },
  ficha_promo_entradas_2_dias: {
    parent: 'distr_promociones',
    page: 'ficha_promo_entradas_2_dias',
    name: [
      { es: 'portaventura-park-entradas-2-dias' },
      { ca: 'portaventura-park-entradas-2-dias' },
      { fr: 'portaventura-park-entradas-2-dias' },
      { en: 'portaventura-park-entradas-2-dias' },
    ],
  },
  ficha_promo_entradas: {
    parent: 'distr_promociones',
    page: 'ficha_promo_entradas',
    name: [
      { es: 'entrada' },
      { ca: 'entrada' },
      { fr: 'entrada' },
      { en: 'entrada' },
    ],
  },
  ficha_promo_entrada_hotel: {
    parent: 'distr_promociones',
    page: 'ficha_promo_entrada_hotel',
    name: [
      { es: 'entrada + hotel' },
      { ca: 'entrada + hotel' },
      { fr: 'entrada + hotel' },
      { en: 'entrada + hotel' },
    ],
  },
  ficha_promo_tienda_online: {
    parent: 'distr_promociones',
    page: 'ficha_promo_tienda_online',
    name: [
      { es: 'Tienda Online' },
      { ca: 'Tienda Online' },
      { fr: 'Tienda Online' },
      { en: 'Tienda Online' },
    ],
  },
  ficha_promo_promocion_otono: {
    parent: 'distr_promociones',
    page: 'ficha_promo_promocion_otono',
    name: [
      { es: 'Promoción Otoño' },
      { ca: 'Promoción Otoño' },
      { fr: 'Promoción Otoño' },
      { en: 'Promoción Otoño' },
    ],
  },
  ficha_promo_hotel: {
    parent: 'distr_promociones',
    page: 'ficha_promo_hotel',
    name: [{ es: 'Hotel' }, { ca: 'Hotel' }, { fr: 'Hotel' }, { en: 'Hotel' }],
  },
  ficha_promo_black_friday: {
    parent: 'distr_promociones',
    page: 'ficha_promo_black_friday',
    name: [
      { es: 'Black Friday' },
      { ca: 'Black Friday' },
      { fr: 'Black Friday' },
      { en: 'Black Friday' },
    ],
  },
  ficha_club_oferta_pase_anual: {
    parent: 'distr_promociones',
    page: 'ficha_club_oferta_pase_anual',
    name: [
      { es: 'Club Oferta Pase Anual' },
      { ca: 'Club Oferta Pase Anual' },
      { fr: 'Club Oferta Pase Anual' },
      { en: 'Club Oferta Pase Anual' },
    ],
  },
  ficha_promo_descuento_entradas: {
    parent: 'distr_promociones',
    page: 'ficha_promo_descuento_entradas',
    name: [
      { es: 'Descuento entradas' },
      { ca: 'Descuento entradas' },
      { fr: 'Descuento entradas' },
      { en: 'Descuento entradas' },
    ],
  },
  ficha_promo_vacaciones_verano: {
    parent: 'distr_promociones',
    page: 'ficha_promo_vacaciones_verano',
    name: [{ es: 'Hotel' }, { ca: 'Hotel' }, { fr: 'Hotel' }, { en: 'Hotel' }],
  },
  ficha_promo_yatetoca: {
    parent: 'distr_promociones',
    page: 'ficha_promo_yatetoca',
    name: [
      { es: 'entrada' },
      { ca: 'entrada' },
      { fr: 'entrada' },
      { en: 'entrada' },
    ],
  },
  ficha_promo_halloween_passport: {
    parent: 'distr_promociones',
    page: 'ficha_promo_halloween_passport',
    name: [
      { es: 'entrada' },
      { ca: 'entrada' },
      { fr: 'entrada' },
      { en: 'entrada' },
    ],
  },
  ficha_promo_halloween_la_muerte_viva: {
    parent: 'distr_promociones',
    page: 'ficha_promo_halloween_la_muerte_viva',
    name: [
      { es: 'entrada' },
      { ca: 'entrada' },
      { fr: 'entrada' },
      { en: 'entrada' },
    ],
  },
  ficha_promo_halloween_roulette: {
    parent: 'distr_promociones',
    page: 'ficha_promo_halloween_roulette',
    name: [{ es: 'Hotel' }, { ca: 'Hotel' }, { fr: 'Hotel' }, { en: 'Hotel' }],
  },
  veganos: {
    parent: 'index',
    page: 'veganos',
    name: [
      { es: 'Veganos' },
      { ca: 'Veganos' },
      { fr: 'Veganos' },
      { en: 'Veganos' },
    ],
  },
  ficha_promo_hotel_portaventura_navidad: {
    parent: 'distr_promociones',
    page: 'ficha_promo_hotel_portaventura_navidad',
    name: [
      { es: 'hoteles-portaventura-navidad-nochevieja' },
      { ca: 'hoteles-portaventura-navidad-nochevieja' },
      { fr: 'hoteles-portaventura-navidad-nochevieja' },
      { en: 'hoteles-portaventura-navidad-nochevieja' },
    ],
  },
  hotel_nochebuena: {
    parent: 'distr_promociones',
    page: 'hotel_nochebuena',
    name: [
      { es: 'hoteles-portaventura-navidad-nochebuena' },
      { ca: 'hoteles-portaventura-navidad-nochebuena' },
      { fr: 'hoteles-portaventura-navidad-nochebuena' },
      { en: 'hoteles-portaventura-navidad-nochebuena' },
    ],
  },
  ficha_promo_cyber_monday: {
    parent: 'distr_promociones',
    page: 'cyber_monday',
    name: [
      { es: 'cyber_monday' },
      { ca: 'cyber_monday' },
      { fr: 'cyber_monday' },
      { en: 'cyber_monday' },
    ],
  },
  ficha_promo_cena_navidad_gratuita: {
    parent: 'distr_promociones',
    page: 'ficha_promo_cena_navidad_gratuita',
    name: [
      { es: 'cena_navidad_gratuita' },
      { ca: 'cena_navidad_gratuita' },
      { fr: 'cena_navidad_gratuita' },
      { en: 'cena_navidad_gratuita' },
    ],
  },
  ficha_promo_tu_regalo_de_navidad: {
    parent: 'distr_promociones',
    page: 'ficha_promo_tu_regalo_de_navidad',
    name: [
      { es: 'tu_regalo_de_navidad' },
      { ca: 'tu_regalo_de_navidad' },
      { fr: 'tu_regalo_de_navidad' },
      { en: 'tu_regalo_de_navidad' },
    ],
  },
  ficha_promo_grupos_vacacionales: {
    parent: 'distr_promociones',
    page: 'ficha_promo_grupos_vacacionales',
    name: [
      { es: 'Grupos Vacacionales' },
      { ca: 'Grupos Vacacionales' },
      { fr: 'Grupos Vacacionales' },
      { en: 'Grupos Vacacionales' },
    ],
  },
};
